<script setup lang="ts">
import { UI05StatusBlock, UI05Tooltip } from 'ui-05'
import { storeToRefs } from 'pinia'
import { declination } from '@artmizu/utils'
import { useConfirmStore } from '~/store/modal/confirm'
import { useOrderModalStore } from '~/store/modal/order'
import { useCartStore } from '~/store/cart'
import type { Product } from '~/type/product/Product'
import { useServiceStore } from '~/store/service'
import { useDeliveryStore } from '~/store/delivery'
import { useSelectPaymentModalStore } from '~/store/modal/selectPayment'
import { useCheckoutStoreModal } from '~/store/modal/checkout'
import { formatTimeFromSeconds } from '~/helper/formatTimeFromSeconds'

const emit = defineEmits<{ (e: 'close'): void }>()
const { $api, $emitter } = useNuxtApp()

const courierPhone = ref('')
const products = ref<Product[]>([])
const phone = shallowRef()
const selectPaymentModalStore = useSelectPaymentModalStore()
const checkoutModalStore = useCheckoutStoreModal()
const orderModalStore = useOrderModalStore()
const { deliveryType } = storeToRefs(useDeliveryStore())
const { list: userCart, currentPaymentCard } = storeToRefs(useCartStore())
const { active: isOrderCancellingModalActive } = storeToRefs(useConfirmStore())
const { isDeliveryDelayed, currentDate } = storeToRefs(useServiceStore())
const order = computed(() => orderModalStore.data!)
const descCondition = computed(() => order.value?.status?.type === 'accepted' || order.value?.status?.type === 'collect' || order.value?.status?.type === 'transit')
const delayStatusCondition = computed(() => order.value.status.type === 'collect' || order.value.status.type === 'transit')
const orderRepeat = computed(() => {
  return order.value?.products?.filter(el => !el?.isUnavailable && el.isUnavailable !== null)
})
const deliveryMinutes = order.value.delivery?.dateEstimateMinutes || 0
const deliveryTime = computed(() => {
  return `${deliveryMinutes} ${declination(deliveryMinutes, ['минуту', 'минуты', 'минут'])}`
})
const orderText = computed(() => (order.value.status.type === 'completed' && order.value.leaveAtTheDoor) ? 'Ваш заказ был оставлен у двери' : 'Ваш заказ')
const delayedTimeText = computed(() => {
  const currentDay = Number(currentDate.value?.split(' ')[0].split('/')[1])
  const deliveryDay = Number((order.value.delivery.date?.split(' ')[0].split('-')[2]))
  const dayText = currentDay === deliveryDay ? 'сегодня' : 'завтра'

  return `Доставим ${dayText} c ${order.value.delivery.date?.split(' ')[1].split('-')[0].slice(0, -3)}`
})

const { enable: enableConfirm, disable: disableConfirm } = useConfirmStore()
const { enable: enableOrder, disable: disableOrder, setCancelledStatus } = useOrderModalStore()
const { setCart } = useCartStore()

const syncApi = useActionEntity($api.cart.sync, {
  params: {
    deliveryType: deliveryType.value,
  },
  onSuccess: ({ data }) => {
    setCart(data.result)
    UI05Tooltip({
      type: 'success',
      title: 'Отлично!',
      description: 'Добавлены все товары, которые есть в наличии',
      mobileOffset: [5, 5, 63, 5],
    })
    /**
      * закрываем окно через 2 секунды, чтобы пользователь мог понять что произошло
    */
    setTimeout(() => {
      emit('close')
    }, 2000)
  },
  onError: () => {
    UI05Tooltip({ title: 'Произошла ошибка', description: 'при добавлении товаров в корзину' })
  },
})

const changeOrderPay = useActionEntity($api.order.updateOrderPay, {
  params: {
    orderId: order.value.id,
    paymentId: order.value.paymentCardID!,
  },
  onSuccess: ({ data }) => {
    orderModalStore.set(data.result)
    nextTick(() => {
      window.open(order.value.link, '_blank')
    })
  },
  onError: ({ serialized }) => {
    UI05Tooltip({
      title: 'Произошла ошибка при смене ссылки на оплату',
      description: serialized?.description,
    })
  },
})

function payOrder() {
  changeOrderPay.request({ orderId: order.value.id, paymentId: order.value.paymentCardID! })
  $emitter.emit('status-order-click-pay-for-the-order', { status: order.value.status.name })
}

const removeOrderApi = useActionEntity($api.order.remove, {
  params: { id: order.value?.id },
  onSuccess: () => {
    disableConfirm()
    useOrderModalStore().onAccess?.callback()
  },
  onError: ({ serialized }) => {
    UI05Tooltip({ title: 'Произошла ошибка при отмене заказа', description: serialized?.description })
  },
})

function onCloseClick() {
  disableOrder()
}

function onOverlayClick(payload: Event) {
  const isOverlay = (payload.target as HTMLElement).classList.contains('order-status__overlay')
  if (isOverlay)
    disableOrder()
}

function onCall() {
  if (order.value?.courierPhone) {
    courierPhone.value = order.value.courierPhone[0]
    phone.value.click()
  }
  $emitter.emit('status-order-click-call-the-courier', { status: order.value.status.name })
}

function addToCart(data: { product: Product[] }) {
  /**
    * Т.к. во фронте в корзине даркстора не добавлен роут синхронизации,
    * чтобы при повторе заказа не удались товары, которые были в корзине прежде,
    * получаем товары с заказа и товары с pinia и делаем запрос синхронизации
  */
  data.product.length
    ? syncApi.request({ data: data.product, cart: userCart.value, deliveryType: deliveryType.value })
    : UI05Tooltip({
      type: 'warning',
      title: 'Невозможно',
      description: 'Этого товара нет в наличии ):',
      mobileOffset: [5, 5, 63, 5],
    })
  products.value = data.product
}

function onSelectPayment() {
  checkoutModalStore.enable()
  selectPaymentModalStore.enable()
}

onActivated(() => {
  document.body.classList.add('modal-overflow')
})

onDeactivated(() => {
  document.body.classList.remove('modal-overflow')
})
</script>

<template>
  <transition
    :duration="{ enter: 400, leave: 400 }"
    enter-from-class="fade-in-up-enter"
    enter-active-class="fade-in-up-enter-active"
    enter-to-class="fade-in-up-enter-to"
    leave-from-class="fade-out-down-leave"
    leave-active-class="fade-out-down-leave-active"
    leave-to-class="fade-out-down-leave-to"
  >
    <div class="order-status__wrapp">
      <div
        class="order-status__overlay"
        @click="onOverlayClick"
      >
        <div class="order-status__modal">
          <div class="order-status__modal-head">
            <div class="order-status__number">
              Заказ №{{ order.number }}
            </div>
            <div
              v-if="order.status"
              class="order-status__title"
              :class="[{ 'order-status__title_margin': !descCondition }]"
            >
              {{ order.status.type === 'delayed' ? 'Отложенная доставка' : order.status.name }}
            </div>
            <div
              v-if="order.status"
              class="order-status__modal-close"
              @click="onCloseClick"
            />
            <div :class="`order-status__img-wrap order-status__img-wrap_${order.status.type}`">
              <div class="order-status__img" />
            </div>

            <Timer
              v-if="order.status.type === 'not-paid' && order.payLinkExpireTime"
              v-slot="{ time }"
              :time="order.payLinkExpireTime"
              class="order-status__not-paid-timer"
            >
              <div v-if="time" class="order-status__not-paid mb-15">
                <div class="order-status__not-paid-block">
                  <span class="order-status__not-paid-text">Оплатите заказ: </span>
                  <span class="order-status__not-paid-value">{{ formatTimeFromSeconds(time) }}</span>
                </div>
              </div>
            </Timer>
            <div
              v-if="order.status.type === 'delayed'"
              class="order-status__description"
            >
              {{ delayedTimeText }}
            </div>
            <div
              v-else-if="delayStatusCondition && deliveryMinutes > 0"
              class="order-status__description"
            >
              {{ `Доставим через ~${deliveryTime}` }}
            </div>
            <div
              v-else-if="order.status && order.status.type === 'not-paid'"
              class="order-status__description"
            >
              {{ 'Оплата не прошла, повторите снова' }}
            </div>
            <div v-if="(order.isDelay || deliveryMinutes <= 0) && delayStatusCondition" class="order-status__changes">
              Заказ задерживается
            </div>
            <div v-else-if="order.isChanged" class="order-status__changes">
              В заказе есть изменения
            </div>
            <div v-else-if="order.status.type === 'cancelled' && order.payResult" class="order-status__changes">
              {{ order.payResult }}
            </div>
            <div v-if="isDeliveryDelayed && descCondition" class="order-status__delivery-delayed-note">
              <DeliveryDelayedNote />
            </div>
            <div class="order-status__actions-block">
              <a
                :href="order.supportLink"
                target="_blank"
                class="order-status__action"
                @click="$emitter.emit('status-order-click-support-chat', { status: order.status.name })"
              >
                <div class="order-status__action-img order-status__action-img_chat" />
                <div class="order-status__action-text">Чат поддержки</div>
              </a>
              <div
                v-if="order.status && order.status.type !== 'not-paid'"
                class="order-status__action"
                @click="
                  addToCart({ product: orderRepeat }),
                  disableOrder(),
                  $emitter.emit('status-order-click-repeat-order', { status: order.status.name })
                "
              >
                <div class="order-status__action-img order-status__action-img_repeat-order" />
                <div class="order-status__action-text">
                  Повторить заказ
                </div>
              </div>
              <div
                v-else
                :href="order.link"
                class="order-status__action"
                target="_blank"
                @click="payOrder()"
              >
                <div class="order-status__action-img order-status__action-img_pay-order" />
                <div class="order-status__action-text">
                  Оплатить заказ
                </div>
              </div>
              <div
                v-if="
                  order.status && (order.status.type === 'accepted' || order.status.type === 'collect' || order.status.type === 'not-paid' || order.status.type === 'delayed')
                "
                class="order-status__action"
                @click="
                  disableOrder(),
                  enableConfirm({
                    title: 'Отменить заказ?',
                    desc: 'После отмены содержимое заказа автоматически сбросится',
                    onConfirm: async () => {
                      await removeOrderApi.request({ id: order.id })
                      !isOrderCancellingModalActive && setCancelledStatus()
                      $emitter.emit('status-order-click-cancel-the-order', { status: order.status.name })
                    },
                    onReject: () => {
                      disableConfirm()
                      enableOrder({ data: order })
                    },
                  })
                "
              >
                <div class="order-status__action-img order-status__action-img_cancelled-order" />
                <div class="order-status__action-text">
                  Отменить заказ
                </div>
              </div>
              <div
                v-if="order.status.type === 'transit' && order.courierPhone"
                class="order-status__action"
                @click="onCall"
              >
                <div class="order-status__action-img order-status__action-img_call-courier" />
                <div class="order-status__action-text">
                  Звонок курьеру
                </div>
              </div>
            </div>
            <div
              v-if="order.status.type === 'not-paid'"
              class="order-status__payment-card"
              @click="onSelectPayment"
            >
              <span class="order-status__payment-title">Оплата спишется:</span>
              <span class="order-status__payment-number">{{ currentPaymentCard ? `${currentPaymentCard.type} · ${currentPaymentCard.cardNumberShort}` : 'c другой карты' }}</span>
            </div>
          </div>
          <div class="order-status__modal-body">
            <div class="order-status__address-block">
              <div class="order-status__address-block-title">
                Адрес доставки
              </div>
              <div class="order-status__address">
                {{ order.delivery.detail?.address }}
              </div>
            </div>
            <div class="order-status__order-block">
              <div class="order-status__order-title">
                <span class="order-status__order-title-text">{{ orderText }}</span>
                <Price
                  v-if="order.price"
                  :price="order.price"
                  size="big"
                  :changed-price="order.changedPrice"
                />
              </div>
              <template v-if="order.products">
                <OrderProduct
                  v-for="item in order.products"
                  :key="item.id"
                  class="mb-10"
                  :product="item"
                  :is-changed="order.isChanged"
                />
              </template>
            </div>
          </div>
        </div>
        <a
          ref="phone"
          :href="`tel:${courierPhone}`"
        />
      </div>
      <div v-if="removeOrderApi.error.value">
        <UI05StatusBlock
          size="m"
          type="error"
          margin="auto"
          title="При отмене заказа произошла ошибка"
        />
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.order-status {

  &__wrapp {
    display: contents;
  }

  &__payment-title {
    font-size: 16px;
    line-height: 18px;
    color: #2C2E33;
  }

  &__payment-number {
    font-size: 16px;
    line-height: 20px;
    color: #818181;

    &::after {
      position: absolute;
      top: 21px;
      width: 5px;
      height: 9px;
      margin-left: 10px;
      content: "";
      background: url('./asset/arrow-right.svg') no-repeat center;
      background-size: 5px auto;
    }
  }

  &__payment-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 30px 16px 16px;
    margin: -10px 0 40px;
    cursor: pointer;
    background-color: #F6F6F6;
    border-radius: 20px;
  }

  &__delivery-delayed-note {
    order: 0;
    padding: 8px 10px;
    background-color: #F3FAFF;
    border-radius: 12px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-above-modal);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* Оставляем 100vh, на случай, если не сработает -webkit-fill-available */
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding-right: 20px;
    margin-right: -20px;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    will-change: transform, opacity;
  }

  &__modal {
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    max-height: 90vh;
    margin: auto;
    overflow-y: scroll;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__modal-head {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 0;
    background-image: linear-gradient(90deg, #ededff 0%, rgba(250, 230, 237, 0.44) 100%);
    background-repeat: no-repeat;
    background-size: 100% 110px;
  }

  &__number {
    font-size: 16px;
    line-height: 18px;
    color: #818181;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;

    &_margin {
      margin: 0;
    }
  }

  &__img-wrap {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: 0 auto 10px;
    background-color: #ffc531;
    border: 5px solid #fff;
    border-radius: 50%;

    &_delayed {
      background-color: #007AFF;

      .order-status__img {
        width: 26px;
        height: 26px;
        background-image: svg-load('./asset/delivery.svg', fill=transparent);
      }
    }

    &_accepted {

      .order-status__img {
        width: 17px;
        height: 11px;
        background-image: svg-load('./asset/order-accepted-icon.svg', fill=transparent);
      }
    }

    &_collect {

      .order-status__img {
        width: 26px;
        height: 26px;
        background-image: svg-load('./asset/order-cart-icon.svg', fill=transparent);
      }
    }

    &_transit {

      .order-status__img {
        width: 29px;
        height: 29px;
        background-image: svg-load('./asset/order-transit-icon.svg', fill=transparent);
      }
    }

    &_completed {

      .order-status__img {
        width: 26px;
        height: 26px;
        background-image: svg-load('./asset/order-completed-icon.svg', fill=transparent);
      }
    }

    &_not-paid {
      background-color: #e30613;

      .order-status__img {
        width: 25px;
        height: 25px;
        background-image: svg-load('./asset/order-not-paid-icon.svg', fill=transparent);
      }
    }

    &_cancelled {
      background-color: #e30613;

      .order-status__img {
        width: 20px;
        height: 20px;
        background-image: svg-load('./asset/order-cancelled-icon.svg', fill=transparent);
      }
    }
  }

  &__img {
    background-position: center;
  }

  &__changes {
    padding: 6px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #E30613;
    border-radius: 4px;
  }

  &__not-paid {
    padding: 11px 15px;
    background-color: #FFF2F3;
    border-radius: 12px;

    &-block {
      display: flex;
      column-gap: 20px;
      align-items: center;
    }

    &-text,
    &-value {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      color: #E30613;
    }

    &-value {
      font-weight: 400;
    }
  }

  &__description {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #9b9b9b;
    text-align: center;
  }

  &__actions-block {
    display: flex;
    justify-content: center;
    margin: 15px 0 40px;
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 75px;
    margin-right: 20px;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }
  }

  &__action-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #f7f7f7;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;

    &_chat {
      background-image: svg-load('./asset/chat.svg', fill=transparent);
    }

    &_repeat-order {
      background-image: svg-load('./asset/repeat-order.svg', fill=transparent);
    }

    &_pay-order {
      background-image: svg-load('./asset/pay-order.svg', fill=transparent);
    }

    &_cancelled-order {
      background-image: svg-load('./asset/cancelled-order.svg', fill=transparent);
    }

    &_call-courier {
      background-image: svg-load('./asset/call-courier.svg', fill=transparent);
    }
  }

  &__action-text {
    font-size: 14px;
    line-height: 16px;
    color: #818181;
    text-align: center;
  }

  &__address-block {
    margin-bottom: 30px;
  }

  &__address-block-title,
  &__order-title {
    padding-bottom: 15px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    border-bottom: 1px solid #f2f2f2;
  }

  &__address {
    font-size: 16px;
    line-height: 20px;
  }

  &__order-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__modal-close {
    position: absolute;
    top: 32px;
    right: 30px;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    cursor: pointer;
    background-image: svg-load('./asset/close.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 12px;

    &:hover {
      background-image: svg-load('./asset/close.svg', fill=#ee272d);
      transition: background-image 0.2s ease-out;
    }
  }

  &__modal-body {
    padding: 0 25px 25px;
  }

  @media (max-width: 900px) {

    &__payment-card {
      order: 5;
      margin: 15px 0 0;
    }

    &__delivery-delayed-note {
      order: 3;
    }

    &__overlay {
      padding-top: 70px;
    }

    &__modal {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      min-height: 100%;
      background-color: #f5f5f5;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.08);
    }

    &__modal-head {
      flex-shrink: 0;
      padding: 25px;
      margin-bottom: 5px;
      background-color: #fff;
      background-image: none;
      border-radius: 20px;
    }

    &__modal-body {
      flex-grow: 1;
      padding: 0 0 15px;
    }

    &__modal-close {
      top: 20px;
      right: 15px;
    }

    &__number {
      order: 1;
      margin-bottom: 20px;
      color: #2C2E33;
    }

    &__img-wrap {
      order: 2;
      margin-bottom: 5px;
      border: 0;
    }

    &__title {
      order: 3;
      margin-bottom: 5px;
      text-align: center;
    }

    &__changes {
      order: 5;
    }

    &__not-paid-timer {
      order: 3;
    }

    &__description {
      order: 4;
    }

    &__actions-block {
      order: 5;
      margin: 15px 0 0;
    }

    &__address-block {
      padding: 15px;
      margin-bottom: 5px;
      background-color: #fff;
      border-radius: 20px;
    }

    &__order-block {
      padding: 15px;
      background-color: #fff;
      border-radius: 20px;
    }
  }

  @media (max-width: 800px) {

    &__modal {
      margin: initial;
      /* Стиль для того чтобы прибивать модалки к низу на мобильном разрешении */
      margin-top: auto;
    }
  }
}

/* Кастомная анимация появления */
@keyframes customFadeInUp {

  from {
    transform: translateY(70px);
  }

  to {
    transform: translateY(0);
  }
}

/* Кастомная анимация исчезновения */
@keyframes customFadeOutDown {

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(70px);
  }
}

.fade-out-down-leave-active,
.fade-in-up-enter-active {
  transition: opacity 0.4s;

  .order-status__modal {
    animation: 0.3s customFadeInUp;
  }
}

.fade-in-up-enter,
.fade-out-down-leave-active {
  transition: opacity 0.5s;

  .order-status__modal {
    animation: 0.5s customFadeOutDown;
  }
}
</style>
