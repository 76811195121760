import { defineStore } from 'pinia'

export const useFeedbackModal = defineStore('feedbackModal', () => {
  const active = ref(false)
  const title = ref<string | undefined>(undefined)

  function enable(obj?: { title: string }) {
    active.value = true
    title.value = obj?.title
  }

  function disable() {
    active.value = false
    title.value = undefined
  }

  return { title, active, enable, disable }
})
