<script setup lang="ts">
import { UI05StatusBlock } from 'ui-05'
import { useProductStore } from '~/store/modal/product'

const { $api } = useNuxtApp()
const productModalStore = useProductStore()
const route = useRoute()
const productActive = computed(() => productModalStore.active)
const productID = computed(() => productModalStore.id)

let actionFavorite: ReturnType<typeof useActionFavorite> | null

const productData = await useActionEntity($api.product.getByID, {
  key: `product-modal-${productID.value}`,
  params: { id: String(productID.value), path: route.path },
})

watch(() => productActive.value, () => {
  if (productID.value) {
    productData.request({ id: String(productID.value), path: productModalStore.path! })
    actionFavorite = useActionFavorite(productID.value, productData.data.value?.product.price?.common?.value || 0)
  }
})

function close() {
  productModalStore.disable(route.fullPath)
}

function onOverlayClick(event: MouseEvent) {
  const isOverlay = (event.target as HTMLDivElement)?.classList.contains('modal-overlay')
  if (isOverlay)
    close()
}
</script>

<template>
  <transition
    :duration="{ enter: 400, leave: 400 }"
    enter-from-class="custom-fade-in-up-enter"
    enter-active-class="custom-fade-in-up-enter-active"
    enter-to-class="custom-fade-in-up-enter-to"
    leave-from-class="$custom-fade-out-down-leave"
    leave-active-class="$custom-fade-out-down-leave-active"
    leave-to-class="$custom-fade-out-down-leave-to"
  >
    <div
      v-if="productActive"
      class="modal-overlay modal-overlay_full modal-overlay_mobile-fullscreen"
      @click="onOverlayClick"
    >
      <div class="product-modal">
        <UI05StatusBlock
          v-if="productData.loading.value"
          size="m"
          type="loading"
          margin="auto"
        />
        <UI05StatusBlock
          v-else-if="productData.error.value"
          size="m"
          type="error"
          margin="auto"
          title="При загрузке товара произошла ошибка"
        />
        <template v-else-if="productData.data.value && productData.success">
          <div class="product-modal__head">
            <div class="product-modal__breadcrumb">
              <Breadcrumb
                v-if="productData.data.value"
                :list="productData.data.value.breadcrumb"
                :root-icon="false"
                :is-last-link-active="true"
                size="small"
                @click="close"
              />
            </div>
            <div class="product-modal__icon-wrap">
              <div v-if="actionFavorite" class="product-modal__tools">
                <div class="product-modal__tool">
                  <UserTool
                    :active="actionFavorite.active.value"
                    :loading="actionFavorite.loading.value"
                    @add="actionFavorite.add"
                    @remove="actionFavorite.remove"
                  />
                </div>
              </div>
              <div
                class="product-modal__close"
                @click="close"
              />
            </div>
          </div>
          <div
            class="product-modal__body"
          >
            <Product
              :data="productData.data.value?.product"
              :similar="productData.data.value?.similar"
            />
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.product-modal {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  min-height: calc(61vh - 100px);
  padding: 28px 30px;
  margin: 10px auto 0;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.06);

  &__breadcrumb {
    position: relative;
    top: 4px;
  }

  &__tools {
    margin-right: 5px;
    margin-left: 20px;
  }

  &__icon-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__head {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__close {
    position: relative;
    top: 2px;
    flex-shrink: 0;
    width: 12px;
    height: 21px;
    margin-left: 11px;
    cursor: pointer;
    background-image: svg-load('./asset/close.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 12px;

    &:hover {
      background-image: svg-load('./asset/close.svg', fill=#ee272d);
      transition: background-image 0.2s ease-out;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 12px;
  }
  @media (max-width: 800px) {
    max-width: 100%;
    height: auto;
    min-height: 100%;
    padding: 12px 15px 0;
    margin: 0;
    border-right: 0;
    border-bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &__breadcrumb {
      display: none;
    }

    &__head {
      justify-content: flex-end;
    }

    &__tools {
      margin-top: 19px;
      margin-right: 43px;
    }

    &__close {
      position: absolute;
      top: 12px;
      right: 7px;
      z-index: 1;
      width: 27px;
      height: 27px;
      background-color: #fff;
      border-radius: 50%;
    }

    &__body {
      margin-top: 0;
    }
  }
}
</style>

<style lang="postcss" scoped>
.modal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  z-index: var(--z-above-modal);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 20px 30px 0;
  margin-right: -20px;
  overflow-y: scroll;
  opacity: 1;
  will-change: transform, opacity;

  &_full {
    position: fixed;
    bottom: 0;
    left: 0;
    /* Оставляем 100vh, на случай, если не сработает -webkit-fill-available */
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background-color: rgba(0, 0, 0, 0.6);
  }

  @media (max-width: 800px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Кастомная анимация появления */
@keyframes customFadeInUp {

  from {
    transform: translateY(70px);
  }

  to {
    transform: translateY(0);
  }
}

/* Кастомная анимация исчезновения */
@keyframes customFadeOutDown {

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(70px);
  }
}

.custom-fade-out-down-leave-active,
.custom-fade-in-up-enter-active {
  transition: opacity 0.4s;

  .modal {
    animation: 0.3s customFadeInUp;
  }
}

.custom-fade-in-up-enter,
.custom-fade-out-down-leave-active {
  opacity: 0;

  .modal {
    animation: 0.3s customFadeOutDown;
  }
}
</style>
