<script setup lang="ts">
import { UI05Button, UI05Form, UI05FormElement, UI05InputText, UI05Modal } from 'ui-05'
import { useFeedbackModal } from '~/store/modal/feedback'

const { $api } = useNuxtApp()
const router = useRouter()
const feedbackModal = useFeedbackModal()
const feedbackParams = ref({
  source: feedbackModal.title,
  comment: undefined,
})

const sendFeedbackAction = useActionEntity($api.feedback.sendFeedback, {
  params: feedbackParams,
  errorType: 'simple',
})

function onSubmit() {
  sendFeedbackAction.request()
}

function onClose() {
  feedbackModal.disable()
  router.push('/')
}
</script>

<template>
  <UI05Modal
    class="ui-modal"
    max-width="400px"
    :title="!sendFeedbackAction.success.value ? 'Не нашли то что искали?' : ''"
    :show="feedbackModal.active"
    @close="feedbackModal.disable"
  >
    <ValidationForm
      v-if="!sendFeedbackAction.success.value"
      v-slot="{ handleSubmit }"
      as="div"
    >
      <UI05Form
        type="inline"
        :adaptive-on900="true"
        class="feedback-form"
        @submit="handleSubmit($event, onSubmit)"
      >
        <div class="feedback-form__subtitle">
          Мы усердно работаем над улучшением <br> ассортимента и очень ценим Вашу обратную связь
        </div>
        <ValidationField
          v-slot="{ field, errors }"
          rules="required"
          name="Обратная связь"
        >
          <UI05FormElement
            autocomplete="off"
            :error="errors[0]"
          >
            <UI05InputText
              v-model="feedbackParams.comment"
              class="feedback-form__input"
              placeholder="Что добавить?"
              :error="!feedbackParams.comment && errors[0]"
              v-bind="field"
              border-radius="8px"
            />
          </UI05FormElement>
        </ValidationField>
        <UI05Button
          v-if="sendFeedbackAction.status.value === 'loading'"
          size="49"
          fluid
          theme="turquoise"
          loading
        />
        <UI05Button
          v-else
          size="49"
          fluid
          theme="turquoise"
          name="send-feedback"
        >
          Отправить
        </UI05Button>
      </UI05Form>
    </ValidationForm>
    <div v-else class="submit">
      <img
        class="submit__img"
        src="./asset/lomtik.svg"
        alt="Ломтик"
      >
      <div class="submit__title">
        Пожелание принято
      </div>
      <UI05Button
        size="49"
        fluid
        theme="turquoise"
        name="close-feedback"
        @click="onClose"
      >
        На главную
      </UI05Button>
    </div>
  </UI05Modal>
</template>

<style lang="postcss" scoped>
.feedback-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  border-top: 1px solid #D6D6D6;

  &__subtitle {
    line-height: 18px;
    color:#838383;
  }

  &__input {
    margin-top: 15px;
  }
}

.submit {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    width: 66px;
    height: 66px;
  }

  &__title {
    margin: 15px 0 25px 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }
}

.ui-modal :deep(.modal) {
  border-radius: 10px;
}

.ui-modal :deep(.h4) {
  font-size: 24px;
  line-height: 27px;
}

@media (max-width: 800px) {

  .ui-modal :deep(.modal) {
    border-radius: 20px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form_adaptive-w900 .form-element__row {
    margin-right: 15px;
  }
}
</style>
