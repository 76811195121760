<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { UI05Button, UI05Modal } from 'ui-05'
import { useCartStore } from '~/store/cart'
import { useDeliveryStore } from '~/store/delivery'

defineProps<{ active: boolean }>()
const emit = defineEmits<{ (event: 'close'): void }>()

const cartStore = useCartStore()
const { $api, $emitter } = useNuxtApp()
const { deliveryType } = storeToRefs(useDeliveryStore())

const removeAll = useActionEntity($api.cart.removeAll, {
  params: deliveryType.value,
  errorType: 'simple',
  onSuccess() {
    cartStore.reset()
  },
})

function onRemoveAll() {
  $emitter.emit('cart-product-remove-list', { list: cartStore.list })
  emit('close')
  removeAll.request()
}
</script>

<template>
  <UI05Modal
    title="Очистить корзину?"
    :show="active"
    @close="emit('close')"
  >
    <span class="modal__trash-modal-subtitle">
      После очищения корзины действие нельзя будет отменить, вы действительно хотите очистить корзину?
    </span>
    <div class="modal__trash-modal-buttons">
      <UI05Button
        fluid
        size="39"
        theme="white"
        @click="emit('close')"
      >
        Нет
      </UI05Button>
      <UI05Button
        fluid
        size="39"
        theme="turquoise"
        @click="onRemoveAll"
      >
        Да, очистить
      </UI05Button>
    </div>
  </UI05Modal>
</template>

<style lang="postcss" scoped>
.modal {

  &__trash-modal-subtitle {
    line-height: 21px;
  }

  &__trash-modal-buttons {
    display: flex;
    column-gap: 14px;
    margin-top: 25px;
  }
}
</style>
