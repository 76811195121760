import { stringify } from 'qs'

export default {
  async sendFeedback({ source, comment }: { source?: string; comment?: string }) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.post('/api/v1/darkstore/feedback/wishes-products', stringify({ source, comment }))

    return data
  },
}
