import { differenceInMinutes, parseISO } from 'date-fns'
import OrderProduct from './OrderProduct'
import formatDate from '~/helper/formatDate'
import type { Order, StatusType } from '~/type/order/Order'

export default function (el: {
  [key: string]: any
  PRODUCTS: any[]
}): Order {
  function getStatusType(el: any): StatusType {
    switch (el.STATUS_ID.ID) {
      case 'M':
      case 'PP':
        return 'not-paid'
      case 'CU':
      case 'Q':
      case 'RJ':
        return 'cancelled'
      case 'W':
        return 'accepted'
      case 'B':
      case 'CM':
      case 'RS':
        return 'collect'
      case 'DD':
      case 'G':
        return 'transit'
      case 'F':
      case 'E':
        return 'completed'
      default:
        return 'not-paid-yet'
    }
  }

  function getDeliveryEstimateMinutes(estimateDate: string) {
    if (!estimateDate)
      return

    const targetDate = parseISO(estimateDate)
    const currentDate = new Date()

    if (targetDate < currentDate)
      return 0

    return differenceInMinutes(targetDate, currentDate)
  }

  function getPayExpireSeconds(date: string) {
    const targetDate = parseISO(date)
    return Math.max(Math.ceil((+targetDate - +new Date()) / 1000), 0)
  }

  return {
    id: el.ID,
    date: formatDate(el.DATE_INSERT, 'd MMMM'),
    number: el.ACCOUNT_NUMBER,
    price: {
      common: {
        value: el.PRICE,
      },
    },
    status: {
      name: el.STATUS_ID?.NAME,
      description: el?.STATUS_DESCRIPTION,
      color: el.STATUS_ID?.COLOR,
      /*
        если пришла дата, значит это отложенный тип доставки
      */
      type: (el.DELIVERY_DATE && getStatusType(el) !== 'not-paid' && getStatusType(el) !== 'not-paid-yet' && getStatusType(el) === 'accepted') ? 'delayed' : getStatusType(el),
    },
    delivery: {
      name: el.DELIVERY_NAME,
      date: el.DELIVERY_DATE,
      dateEstimate: el.DARKSTORE_DELIVERY_ESTIMATE ? formatDate(el.DARKSTORE_DELIVERY_ESTIMATE, 'd MMMM') : undefined,
      dateEstimateMinutes: el.DARKSTORE_DELIVERY_ESTIMATE_ISO
        ? getDeliveryEstimateMinutes(el.DARKSTORE_DELIVERY_ESTIMATE_ISO)
        : 0,
      detail: {
        address: el.USER_ADDRESS,
      },
    },
    payResult: el.PAY_RESULT,
    isEvaluated: el.ORDER_EVALUATED,
    checkLink: el?.DARKSTORE_PAY_CHECK_LINK,
    isDelay: el.DARKSTORE_DELIVERY_DELAY === 'DELAY',
    courierPhone: el?.DARKSTORE_COURIER_PHONE,
    supportLink: el.DARKSTORE_SUPPORT_LINK,
    pay: el.PAY_SYSTEM_NAME,
    leaveAtTheDoor: el?.DARKSTORE_LEAVE_AT_THE_DOOR,
    link: el.PAY_SYSTEM_LINK,
    freeDeliveryPrice: el.FREE_DELIVERY_PRICE ? +el.FREE_DELIVERY_PRICE : 0,
    products: el.PRODUCTS?.map((el: any) => OrderProduct(el)) || [],
    isChanged: el.DARKSTORE_HAS_CHANGES,
    changedPrice: el.PREVIOUS_PRICE || 0,
    paymentCardID: el.PAY_CARD_ID,
    payLinkExpireTime: el.PAY_SYSTEM_LINK_EXPIRE_DATE && getPayExpireSeconds(el.PAY_SYSTEM_LINK_EXPIRE_DATE),
  }
}
